import { translateCurrentBenefitToLegacyBenefit } from "v2/infrastructure/common/parsers/translateCurrentBenefitToLegacyBenefit";
import apiClient from "./api-client";

export async function getDeliveriesBenefitsAvailableForEmployees(employeeId) {
  const url = `/v1/back-office/employees/${employeeId}/deliveries/benefits`;
  const res = await apiClient().get(url);
  const benefits = res?.data?.content?.map((benefit) =>
    translateCurrentBenefitToLegacyBenefit(benefit)
  );
  return benefits || [];
}
